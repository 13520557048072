<div class="dialog-container">

    <div class="dialog-header" style="display: flex;
    align-items: center;
    justify-content: space-between;position: relative;">
        <div class="title-header" style="flex: 1; padding: 10px; margin-left: 12px; margin-top: 10px;">
            <h1 style="font-size: 24px;">Confirmation</h1>
        </div>
        <div class="close-button"><button mat-icon-button (click)="onCancel()" class="close-button"
                style="display: flex;align-items: center; margin-right: 12px;">
                <mat-icon>close</mat-icon>
            </button></div>
        <hr />

    </div>
    <div mat-dialog-content>
        <p>Are you sure you want to delete <strong> {{ data.name }}</strong>? This action cannot be undone.</p>
    </div>
    <div mat-dialog-actions style="justify-content: flex-end;">
        <button mat-raised-button (click)="onCancel()">Cancel</button>
        <button mat-flat-button style="background-color:#DC362E; color: white; border-radius: 4px;"
            [mat-dialog-close]="true" cdkFocusInitial (click)="onDelete()">Delete</button>
    </div>
</div>