import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  private _pageTitleSubject = new BehaviorSubject<string>('');

  get pageTitle$(): Observable<string> {
    return this._pageTitleSubject.asObservable();
  }

  setTitle(title: string): void {
    this._pageTitleSubject.next(title);
  }
}
