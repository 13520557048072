<div class="snackbar-container"
    [ngClass]="{ 'success-border': data.mode === 'success', 'error-border': data.mode === 'error' , 'warning-border': data.mode === 'warning'}">
    <!-- Icon and Message for Success -->
    <div *ngIf="data.mode === 'success'" class="icon-message">
        <div class="success-icon">
            <mat-icon>info</mat-icon>
        </div>
        <div class="message">
            <span class="snackbar-title">{{ data.title }}</span>
            <p class="snackbar-description">{{ data.message }}</p>
        </div>
    </div>

    <div *ngIf="data.mode === 'warning'" class="icon-message">
        <div class="warning-icon">
            <mat-icon>info</mat-icon>
        </div>
        <div class="message">
            <span class="snackbar-title">{{ data.title }}</span>
            <p class="snackbar-description">{{ data.message }}</p>
        </div>
    </div>

    <!-- Icon and Message for Error -->
    <div *ngIf="data.mode === 'error'" class="icon-message">
        <div class="error-icon">
            <mat-icon>cancel</mat-icon>
        </div>
        <div class="message">
            <span class="snackbar-title">{{ data.title }}</span>
            <p class="snackbar-description">{{ data.message }}</p>
        </div>
    </div>

    <button mat-icon-button (click)="closeSnackbar()" class="close">
        <mat-icon>close</mat-icon>
    </button>
</div>



<!-- <div class="snackbar-container">
    <div *ngSwitch="data.mode">
        <ng-container *ngSwitchCase="'success'">

            <div class="icon">
                <mat-icon>check_circle</mat-icon>
            </div>
            <div class="message">
                <span class="snackbar-title">{{ data.title }}</span>
                <p class="snackbar-description">{{ data.message }}</p>
            </div>
        </ng-container>

        <ng-container *ngSwitchCase="'error'">
           
            <div class="icon" style="color: red;">
                <mat-icon>error</mat-icon>
            </div>
            <div class="message">
                <span class="snackbar-title" style="color: red;">{{ data.title }}</span>
                <p class="snackbar-description">{{ data.message }}</p>
            </div>
        </ng-container>
    </div>
    <button mat-icon-button (click)="closeSnackbar()">
        <mat-icon>close</mat-icon>
    </button>
</div> -->