<!-- Navbar -->
<nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
        <li class="nav-item">
            <a class="nav-link" data-widget="pushmenu" role="button" (click)="toggleNavbar()">
                <i class="fas" [ngClass]="isSidebarCollapsed  ? 'fa-chevron-right' : 'fa-chevron-left'"></i>
            </a>
        </li>
        <li class="nav-item d-none d-sm-inline-block">
            <a class="nav-link" style="font-weight: bold; color: #38393a; font-size: 24px">
                {{ pageTitle }}
            </a>
        </li>
    </ul>

    <!-- Right navbar links -->
    <ul class="navbar-nav flex-grow-1 justify-content-end px-3">
        <li class="nav-item dropdown">
            <a 
                class="nav-link dropdown-toggle user-dropdown p-0" 
                href="#" id="navbarDropdownMenuLink" 
                data-toggle="dropdown" 
                aria-haspopup="true" 
                aria-expanded="false"
            >
                <img src="./../../../assets/icons/user.png" class="user-pic">
                <div class="user-email">{{ userEmail }}</div>
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                <button (click)="logout()" class="dropdown-item" type="button" aria-label="Log out">
                    <i class="fas fa-sign-out-alt pr-2 icon"></i> Log out
                </button>
            </div>
        </li>
       
    </ul>
</nav>