import { NavItem } from 'src/assets/models/nav-item';

export const navItems: Array<NavItem> = [
  {
    id: 'nav-dashboard',
    label: 'Dashboard',
    icon: 'assets/icons/dashboard-filled.svg',
    route: '/dashboard',
  },
  {
    id: 'nav-products',
    label: 'Products',
    icon: 'assets/icons/products-d.svg',
    children: [
      {
        id: 'nav-product-list',
        label: 'Product List',
        route: '/product-list',
      }
    ],
  },
  {
    id: 'nav-application',
    label: 'Application',
    icon: 'assets/icons/application-d.svg',
    children: [
      {
        id: 'nav-plugin-list',
        label: 'Plugin List',
        route: '/plugin-list',
      },
      {
        id: 'nav-libraries-component',
        label: 'Libraries Component',
        route: '/libraries-component',
      }
    ],
  },
  {
    id: 'licenses-application',
    label: 'Licenses',
    icon: 'assets/icons/license-d.svg',
    children: [
      {
        id: 'nav-license-list',
        label: 'License List',
        route: '/license-list',
      },
      {
        id: 'nav-license-group-list',
        label: 'License Group List',
        route: '/license-group-list',
      },
    ],
  },
  {
    id: 'nav-user',
    label: 'Users & Profiles',
    icon: 'assets/icons/user.svg',
    children: [
      {
        id: 'nav-user-list',
        label: 'User List',
        route: '/user-list',
      },
      {
        id: 'nav-profile-list',
        label: 'Profile List',
        route: '/profile-list',
      }
    ],
  },
];
