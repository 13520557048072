import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable, firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from 'src/assets/models/user';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private baseUrl = `${environment.licenseServerUrl}`;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private router: Router
  ) {}

  login(username: string, password: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/authenticate`, {
      username,
      password,
    });
  }

  refresh(): Observable<any> {
    let token = this.getCookie('refresh_token');
    return this.http.post(`${this.baseUrl}/refresh-token`, {
      refresh_token: token
    });
  }

  setToken(tokenObj: any): void {
    this.cookieService.set('access_token', tokenObj.access_token);
    this.cookieService.set('refresh_token', tokenObj.refresh_token);
  }

  setCookie(name: string, value: string): void {
    this.cookieService.set(name, value);
  }

  getCookie(name: string): string {
    return this.cookieService.get(name);
  }

  getAccessToken(): string {
    return this.cookieService.get('access_token');
  }

  isLoggedIn(): boolean {
    return !!this.getAccessToken();
  }

  async getAccount(): Promise<User> {
    // Create HttpHeaders object
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.getAccessToken()}`, // Add Bearer token
      'Content-Type': 'application/json'  // Optional, set Content-Type if needed
    });

    const endpoint = `${this.baseUrl}/api/me`;
    const observable: Observable<User> = this.http.get<User>(endpoint, { headers });

    return await firstValueFrom(observable);
  }

  logout(): void {
    this.cookieService.delete('access_token');
    this.cookieService.delete('refresh_token');
    this.cookieService.delete('email');
    this.router.navigate(['/admin']);
  }
}
