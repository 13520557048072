import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  username = '';
  userEmail = '';
  pageTitle: string = '';
  
  @Input() isSidebarCollapsed : boolean = false;
  @Output() toggle = new EventEmitter<void>();
  @Output() titleChanged: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _headerService: HeaderService
  ) { }

  ngOnInit(): void {
    this._getUser();

    this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this._getUser();
        this.updatePageTitle();
      });

    this._headerService.pageTitle$.subscribe((title) => {
      this.pageTitle = title;
    });

    this.updatePageTitle();
  }

  get firstLetter(): string {
    return this.userEmail.charAt(0).toUpperCase();
  }

  toggleNavbar() {
    this.isSidebarCollapsed = !this.isSidebarCollapsed; 
    this.toggle.emit();
  }

  logout(): void {
    this._authService.logout()
  }

  private updatePageTitle(): void {
    const route = this._router.url;

    switch (route) {
      case '/':
        this.pageTitle = this.username;
        break;
      case '/product-list':
        this.pageTitle = 'Product List';
        break;
      case '/product-management':
        this.pageTitle = 'Add New Product';
        break;
      case '/plugin-list':
        this.pageTitle = 'Plugin List';
        break;
      case '/plugin-management':
        this.pageTitle = 'Add New Plugin';
        break;
      case '/user-list':
        this.pageTitle = 'User List';
        break;
      case '/user-management':
        this.pageTitle = 'Add New User';
        break;  
      case '/profile-list':
        this.pageTitle = 'Profile List';
        break;
      case '/license-list':
        this.pageTitle = 'License List';
        break;
      case '/profile-management':
        this.pageTitle = 'Add New Profile';
        break;
      case '/license-management':
        this.pageTitle = 'Add New License';
        break;
      case '/license-group-list':
        this.pageTitle = 'License Group List';
        break;
      case '/license-group-management':
        this.pageTitle = 'Add New License Group';
        break;
      default:
        this.pageTitle = this.username;
    }
  }
  
  private _getUser(): void {
    this.userEmail = this._authService.getCookie('email');
  }
}
