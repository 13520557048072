import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';

export const authGuard: CanActivateFn = (route, state): boolean | UrlTree => {
  
  const currentUrl: string = state.url;
  const authService: AuthService = inject(AuthService);
  const router: Router = inject(Router);

  if (!authService.isLoggedIn()) {

    if (currentUrl != '/admin') {
      return router.createUrlTree(['']);
    }

    return true;
  }

  if (currentUrl == '/admin') {
    return router.createUrlTree(['/dashboard']);
  }

  return true;
};
