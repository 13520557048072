<div class="app-container">

    <app-side-nav 
        class="app-side-nav"
        [isSidebarCollapsed]="isSidebarCollapsed"
        [ngClass]="{ 'hide-component': flagComponent == true }">
    </app-side-nav>
    <div class="main-content">
        <div class="wrapper">
            <app-header 
                class="app-header"
                [isSidebarCollapsed]="isSidebarCollapsed"
                (toggle)="toggleSidebar()"
                [ngClass]="{ 'hide-component': flagComponent == true }"></app-header>

            <div class="content-wrapper" 
                [ngClass]="{ 'expand-component': flagComponent == true }"    
                #contentWrapper>
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>