import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const currentUrl = this.router.url;
    const accessToken = this.authService.getAccessToken();
    let authReq = req;

    if (currentUrl != '/admin') {
      authReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    }

    return next.handle(authReq).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && this.authService.getCookie('refresh_token') != '') {
          return from(this.authService.refresh()).pipe(
            switchMap((newValue: string) => {
              this.authService.setToken(newValue)
              const clonedReq = req.clone({
                setHeaders: {
                  Authorization: `Bearer ${this.authService.getAccessToken()}`,
                },
              });
              return next.handle(clonedReq);
            }),
            catchError(err => {
              this.authService.logout();
              return throwError(err);
            })
          );
        }

        // If it's not a 401 error, just throw the original error
        return throwError(error);
      })
    );
  }
}
