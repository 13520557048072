import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { authGuard } from './guards/auth.guard';

const routes: Routes = [
  { 
    path: '', 
    pathMatch: 'full', 
    redirectTo: 'admin' 
  },
  {
    path: 'admin',
    loadComponent: () => import('./components/admin-login/admin-login.component').then((m) => m.AdminLoginComponent ),
    canActivate: [authGuard],
  },
  {
    path: 'dashboard',
    loadComponent: () => import('./components/dashboard/dashboard.component').then((m) => m.DashboardComponent),
    canActivate: [authGuard],
  },
  {
    path: 'product-management',
    loadComponent: () => import('./components/pages/product-mgmt/product-management/product-management.component').then((m) => m.ProductManagementComponent),
    canActivate: [authGuard],
  },
  { 
    path: 'product-list', 
    loadComponent: () => import('./components/pages/products/products-list/products-list.component').then((m) => m.ProductsListComponent),
    canActivate: [authGuard],
  },
  {
    path: 'user-list',
    loadComponent: () => import('./components/pages/user/user-list/user-list.component').then((m) => m.UserListComponent ),
    canActivate: [authGuard],
  },
  {
    path: 'user-management',
    loadComponent: () => import('./components/pages/user/user-form/user-form.component').then((m) => m.UserFormComponent ),
    canActivate: [authGuard],
  },
  {
    path: 'profile-list',
    loadComponent: () => import('./components/pages/profile/profile-list/profile-list.component').then((m) => m.ProfileListComponent ),
    canActivate: [authGuard],
  },
  {
    path: 'profile-management',
    loadComponent: () => import('./components/pages/profile/profile-form/profile-form.component').then((m) => m.ProfileFormComponent ),
    canActivate: [authGuard],
  },
  {
    path: 'plugin-management',
    loadComponent: () => import('./components/pages/plugin-mgmt/plugin-management/plugin-management.component').then((m) => m.PluginManagementComponent ),
    canActivate: [authGuard],
  },
  {
    path: 'plugin-list',
    loadComponent: () => import('./components/pages/plugin-mgmt/plugin-list/plugin-list.component').then((m) => m.PluginListComponent ),
    canActivate: [authGuard],
  },
  {
    path: 'license-list',
    loadComponent: () => import('./components/pages/licenses/license-list/license-list.component').then((m) => m.LicenseListComponent ),
    canActivate: [authGuard],
  },
  {
    path: 'license-management',
    loadComponent: () => import('./components/pages/licenses/license-form/license-form.component').then((m) => m.LicenseFormComponent ),
    canActivate: [authGuard],
  },
  {
    path: 'license-group-list',
    loadComponent: () => import('./components/pages/license-groups/license-group-list/license-group-list.component').then((m) => m.LicenseGroupListComponent ),
    canActivate: [authGuard],
  },
  {
    path: 'license-group-management',
    loadComponent: () => import('./components/pages/license-groups/license-group-form/license-group-form.component').then((m) => m.LicenseGroupFormComponent ),
    canActivate: [authGuard],
  }

  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
