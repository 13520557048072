<aside 
    class="main-sidebar sidebar-dark-primary elevation-4" 
    (mouseenter)="onMouseEnter()" 
    (mouseleave)="onMouseLeave()">

    <!-- Brand Logo -->
     <div class="brand-link" routerLink="/dashboard">
        <img src="assets/icons/dashboard-d.svg">
        <h5 *ngIf="!isSidebarCollapsed  || expandSidebarOnHover">
            License Server 
        </h5>
    </div>

    <!-- Sidebar -->
    <div class="sidebar">
        <!-- Sidebar Menu -->
        <nav class="mt-2">
            <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">

                <ng-container *ngFor="let navItem of navItems; let idx = index">
                    <li class="nav-item" [ngClass]="{'has-treeview' : navItem.route == undefined}">
                        <ng-container *ngIf="navItem.children == undefined || !navItem.children.length; else hasChild">
                            <a class="nav-link" [id]="navItem.id" routerLink="{{ navItem.route }}" #nav>
                                <img class="nav-icon" [src]="navItem.icon" />
                                <p>{{ navItem.label }}</p>
                            </a>
                        </ng-container>
                    </li>

                    <ng-template #hasChild>
                        <a class="nav-link" [id]="navItem.id" #nav>
                            <img class="nav-icon" [src]="navItem.icon" />
                            <p> {{ navItem.label }}</p>
                            <i *ngIf="!isSidebarCollapsed || expandSidebarOnHover" class="right fas fa-angle-left"></i>
                        </a>
                        <ul class="nav nav-treeview">
                            <ng-container *ngFor="let navChild of navItem.children; let childIdx = index">
                                <li class="nav-item">
                                    <div class="depth-indicator"
                                        [ngClass]="{'last-item' : ((navItem.children!.length - 1) == childIdx) }"></div>
                                    <a class="nav-link" [id]="navChild.id" routerLink="{{ navChild.route }}" #nav>
                                        {{ navChild.label }}
                                    </a>
                                </li>
                            </ng-container>
                        </ul>
                    </ng-template>
                </ng-container>

            </ul>
        </nav>
        <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
</aside>