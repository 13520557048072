import { NavigationEnd, Router } from '@angular/router';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { filter } from 'rxjs/operators';
import { SideNavComponent } from './shared/side-nav/side-nav.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  flagComponent: boolean = true;
  isSidebarCollapsed : boolean = false;

  @ViewChild(SideNavComponent) private sideNavRef!: SideNavComponent;

  constructor(private _router: Router) { }

  ngOnInit(): void { 
    this._router.events
      .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.flagComponent = event.urlAfterRedirects.includes('/admin');
      });
  }

  toggleSidebar() {
    this.isSidebarCollapsed = !this.isSidebarCollapsed;
    this.sideNavRef.toggleSubMenu(!this.isSidebarCollapsed);
  }

  ngAfterViewInit(): void { }
}
