import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NavItem } from 'src/assets/models/nav-item';
import { filter } from 'rxjs/operators';
import { navItems } from './nav-items';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit, AfterViewInit, OnDestroy {
  navItems: Array<NavItem> = navItems;
  expandSidebarOnHover: boolean = false;

  @Input() isSidebarCollapsed: boolean = false;
  @ViewChildren('nav') private _navItemEl!: QueryList<ElementRef>;

  constructor(private _router: Router, private _renderer: Renderer2) { }

  ngOnInit(): void {
    this._router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe((event: NavigationEnd) => {
        this._setNavActiveRoute(event.url);
      });
  }

  ngAfterViewInit(): void { }

  ngOnDestroy(): void { }

  onMouseEnter(): void {
    if (this.isSidebarCollapsed) {
      this.expandSidebarOnHover = true;
      this.toggleSubMenu(this.expandSidebarOnHover);
    }
  }

  onMouseLeave(): void {
    if (this.isSidebarCollapsed) {
      this.expandSidebarOnHover = false;
      this.toggleSubMenu(this.expandSidebarOnHover);
    }
  }

  toggleSubMenu(state: boolean): void {
    const currentRoute = this._router.url;

    this.navItems.forEach((navItem: NavItem) => {
      const foundEl = this._navItemEl.find(el => el.nativeElement.id === navItem.id);

      if (foundEl) {
        const parentLi = foundEl.nativeElement.closest('li');
        const treeviewEl = parentLi?.querySelector('.nav-treeview');

        if (navItem.children) {
          const hasActiveChild = navItem.children.some(child => child.route === currentRoute);

          if (treeviewEl) {
            let shouldDisplayTreeview = state && hasActiveChild;
            this._renderer.setStyle(treeviewEl, 'display', shouldDisplayTreeview ? 'block' : 'none');

            if (shouldDisplayTreeview) {
              this._renderer.addClass(parentLi, 'menu-open');
              this._renderer.addClass(parentLi, 'menu-is-opening');
            } else {
              this._renderer.removeClass(parentLi, 'menu-open');
              this._renderer.removeClass(parentLi, 'menu-is-opening');
            }
          }
        }
      }
    });
  }

  private _setNavActiveRoute(currentRoute: string): void {
    let hasActiveLink = false;
    const activeElements: ElementRef[] = [];

    this._navItemEl.forEach((el: ElementRef) => {
      const parentLi = el.nativeElement.closest('li');

      if (parentLi) {
        this._renderer.removeClass(parentLi, 'menu-open');
        this._renderer.removeClass(parentLi, 'menu-is-opening');
      }
      this._renderer.removeClass(el.nativeElement, 'active-link');
    });

    this.navItems.forEach((navItem: NavItem) => {
      const foundEl = this._navItemEl.find(el => el.nativeElement.id === navItem.id);

      if (navItem.route === currentRoute) {
        if (foundEl) {
          this._renderer.addClass(foundEl.nativeElement, 'active-link');
          activeElements.push(foundEl);
          hasActiveLink = true;
        }
        return;
      }

      if (navItem.children) {
        navItem.children.forEach((child: NavItem) => {
          if (child.route === currentRoute) {
            const foundChildEl = this._navItemEl
              .find(el => el.nativeElement.id === child.id);

            if (foundEl) {
              this._renderer.addClass(foundEl.nativeElement, 'active-link');
              activeElements.push(foundEl);
              this._updateMenuState(foundEl, activeElements);
            }

            if (foundChildEl) {
              this._renderer.addClass(foundChildEl.nativeElement, 'active-link');
              activeElements.push(foundChildEl);
            }

            hasActiveLink = true;
          }
        });
      }
    });

    this._navItemEl.forEach((elementRef: ElementRef) => {
      this._updateMenuState(elementRef, activeElements);
    });
  }

  private _updateMenuState(el: ElementRef, activeEl: ElementRef[]) {
    const parentLi = el.nativeElement.closest('li');
    const treeviewEl = parentLi?.querySelector('.nav-treeview');

    if (treeviewEl) {
      const hasActiveChild = activeEl.some(activeEl =>
        activeEl.nativeElement.closest('li') === parentLi
      );

      if (hasActiveChild) {
        this._renderer.addClass(parentLi, 'menu-open');
        this._renderer.addClass(parentLi, 'menu-is-opening');
      }

      let shouldDisplayTreeview = this.expandSidebarOnHover
        ? hasActiveChild
        : hasActiveChild && !this.isSidebarCollapsed;

      this._renderer.setStyle(treeviewEl, 'display', (shouldDisplayTreeview ? 'block' : 'none'));
    }
  }
}